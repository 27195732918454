<template>
  <div class="list-view">
    <page-title>
      <!-- <el-button
                @click="addRow"
                type="primary"
                icon="el-icon-plus"
                :disabled="fetchingData || downloading"
                class="filter-item"
            >
                新增
            </el-button> -->
      <el-button @click="download"
                 icon="el-icon-upload2"
                 :loading="downloading"
                 :disabled="fetchingData"
                 class="filter-item">
        导出
      </el-button>
    </page-title>
    <div class="filters-container">
      <el-form :model="formData"
               :inline="true">
        <el-form-item label="支付时间">
          <created-at-picker v-model="formData.payTime"
                             @input="initData"
                             name="支付"></created-at-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="formData.status"
                     placeholder="筛选状态"
                     clearable
                     @change="initData"
                     style="margin-right: 10px">
            <el-option v-for="(value,key) in orderStatusMap"
                       :key="key"
                       :value="key"
                       :label="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model="formData.orderNo"
                    placeholder="订单编号"
                    clearable=""
                    @change="initData"></el-input>
        </el-form-item>
        <el-form-item label="交易流水">
          <el-input v-model="formData.transactionId"
                    placeholder="交易流水"
                    clearable=""
                    @change="initData"></el-input>
        </el-form-item>
        <el-form-item label="藏品ID">
          <el-input v-model="formData.collectionId"
                    placeholder="藏品ID"
                    clearable=""
                    @change="initData"></el-input>
        </el-form-item>
        <el-form-item label="藏品名称">
          <el-input v-model="formData.name"
                    placeholder="藏品名称"
                    clearable=""
                    @change="initData"></el-input>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="formData.userId"
                    placeholder="用户ID"
                    clearable=""
                    @change="initData"></el-input>
        </el-form-item>
      </el-form>
      <div class="tool-wrapper">
        <div class="tool-right">
          <el-tag>已完成总金额({{ totalGasAmount || 0}}￥)</el-tag>
        </div>
        <el-input placeholder="搜索..."
                  v-model="search"
                  clearable
                  class="filter-item search"
                  @keyup.enter.native="initData">
          <el-button @click="initData"
                     slot="append"
                     icon="el-icon-search"> </el-button>
        </el-input>
      </div>
      <!-- <el-input placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData">
        <el-button @click="initData"
                   slot="append"
                   icon="el-icon-search"> </el-button>
      </el-input> -->
    </div>

    <el-table :data="tableData"
              row-key="id"
              ref="table"
              header-row-class-name="table-header-row"
              header-cell-class-name="table-header-cell"
              row-class-name="table-row"
              cell-class-name="table-cell"
              :height="tableHeight"
              v-loading="fetchingData">
      <el-table-column v-if="multipleMode"
                       align="center"
                       type="selection"
                       width="50"> </el-table-column>
      <el-table-column prop="id"
                       label="ID"> </el-table-column>
      <el-table-column prop="userId"
                       label="赠送用户ID"
                       width="100px"> </el-table-column>
      <el-table-column prop="nickname"
                       label="赠送用户"> </el-table-column>
      <el-table-column prop="collectionId"
                       label="藏品ID"> </el-table-column>
      <el-table-column prop="name"
                       label="名称"> </el-table-column>
      <el-table-column prop="toUserId"
                       label="收到用户ID"
                       width="100px"> </el-table-column>
      <el-table-column prop="toNickname"
                       label="收到用户"> </el-table-column>
      <el-table-column prop="status"
                       label="状态">
        <!-- <template slot-scope="scope">
                    <el-tag :type="orderStatusMap[scope.row.status].type" v-if="orderStatusMap[scope.row.status]">{{orderStatusMap[scope.row.status]}}</el-tag>
                </template> -->
      </el-table-column>
      <el-table-column prop="payMethod"
                       label="支付方式">
        <!-- <template slot-scope="scope">
                    <el-tag :type="payMethodMap[scope.row.payMethod].type" v-if="payMethodMap[scope.row.payMethod]">{{payMethodMap[scope.row.payMethod]}}</el-tag>
                </template> -->
      </el-table-column>
      <el-table-column prop="gasPrice"
                       label="gas费"> </el-table-column>
      <el-table-column prop="orderNo"
                       label="订单编号"
                       min-width="100"
                       show-overflow-tooltip> </el-table-column>
      <el-table-column prop="transactionId"
                       label="交易流水ID"
                       min-width="100"
                       show-overflow-tooltip> </el-table-column>
      <el-table-column prop="payTime"
                       label="支付时间"
                       width="140"> </el-table-column>
      <el-table-column prop="txHash"
                       label="交易hash"
                       show-overflow-tooltip> </el-table-column>
      <el-table-column prop="gasUsed"
                       label="消耗gas"> </el-table-column>
      <el-table-column prop="blockNumber"
                       label="区块高度"> </el-table-column>
      <el-table-column label="操作"
                       align="center"
                       fixed="right"
                       width="150">
        <template slot-scope="{ row }">
          <el-button @click="editRow(row)"
                     type="primary"
                     size="mini"
                     plain>查看</el-button>
          <!-- <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper">
      <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
      <el-pagination background
                     @size-change="onSizeChange"
                     @current-change="onCurrentChange"
                     :current-page="page"
                     :page-sizes="[10, 20, 30, 40, 50]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="totalElements">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import { orderStatusMap, payMethodMap } from '@/utils/constant.js';

export default {
    name: 'GiftOrderList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            totalGasAmount: '',
            search: '',
            url: '/giftOrder/all',
            downloading: false,
            formData: {
                status: null,
                orderNo: '',
                transactionId: '',
                payTime: '',
                collectionId: '',
                name: ''
            },
            orderStatusMap,
            payMethodMap
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    created() {
        this.getPayAmt();
    },
    methods: {
        payMethodFormatter(row, column, cellValue, index) {
            let selectedOption = this.payMethodOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        statusFormatter(row, column, cellValue, index) {
            let selectedOption = this.statusOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        beforeGetData() {
            return { search: this.search, query: { del: false, ...this.formData } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/giftOrderEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/giftOrderEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            let params = this.beforeGetData();
            params.size = 10000;
            params.query.projectId = this.$store.state.projectId;
            this.$axios
                .post('/giftOrder/excel', params, {
                    responseType: 'blob'
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '转赠订单.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        getPayAmt() {
            // this.searchingMinter = true;
            let query = {
                del: false,
                ...this.$route.query
            };
            delete query.sort;
            delete query.page;
            delete query.size;
            let data = {
                page: this.page - 1,
                size: this.pageSize,
                sort: this.sortStr,
                query: query
            };
            if (this.beforeGetData) {
                let mergeData = this.beforeGetData();
                if (mergeData) {
                    data = {
                        ...data,
                        ...mergeData
                    };
                }
            }
            data.query.projectId = this.$store.state.projectId;
            this.$http
                .post('/giftOrder/totalGasAmount', data, {
                    body: 'json'
                })
                .then(res => {
                    console.log(res, 'res');
                    this.totalGasAmount = res.totalGasAmount;
                    // this.searchingMinter = false;
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/giftOrder/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
.tool-wrapper {
    display: block;
    overflow: hidden;

    .tool-right {
        float: left;
    }
}
</style>
